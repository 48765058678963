import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { Overlay, Spinner, Tooltip } from 'react-bootstrap';

import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';

import { getInitalByFullname } from '../../utils/nameTreatment';

import Profile from '../Profile/index';

import { Agent } from '../../utils/InterFaces';

import hmLogo from '../../assets/hm-logo.svg';

import {
  HeaderContainer,
  LogoContainer,
  TotalClientsContainer,
  TotalClientsLabel,
  TotalClientsValue,
  SearchAndAgentContainer,
  AgentContainer,
  AgentAvatar,
  AgentName,
  AvailabilityIndicator,
  ProfileButton,
  DotIndicador,
  RefreshLeads,
  CallsForRedistribute,
  LastUpdate,
  ButtonShowContacts,
  ButtonsHeader,
  NextAgentsContainer,
  LoadingContainer,
} from './styles';
import { MdLibraryBooks } from 'react-icons/md';
import Select from 'components/Select';

interface HeaderProps {
  total_calls: number;
  callbackFunction: Function;
  //refreshLeads: Function;
  callsForRedistribute: number | null;
  dateAtual: string;
  handleShowContactModal: () => void;
  handleShowReportModal: () => void;
  handleShowExportModal: () => void;
}

const Header: React.FC<HeaderProps> = (props) => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | undefined>(
    undefined,
  );
  const [showAgentProfile, setShowAgentProfile] = useState(false);
  const [available, setAvailable] = useState(false);
  const [showRefreshDialog, setShowRefreshDialog] = useState(false);
  const [permission, setPermission] = useState('');
  const [line, setLine] = useState<any>();
  const [teamList, setTeamList] = useState([{ value: '', label: '' }]);
  const [selectedGroup, setSelectedGroup] = useState('hmsaopaulo');
  const [is_loading, setIs_loading] = useState(true);
  const agentSelected = JSON.parse(sessionStorage.getItem('selectedAgent'));

  const target = useRef(null);

  const callBackParentFunction = props.callbackFunction;

  const getAllAgents = useCallback(async () => {
    const agentsResponse = await api.get('/agents', {
      headers: {
        Authorization: authToken.token,
      },
    });

    const { agents } = agentsResponse.data;

    return agents;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();
      const user = sessionStorage.getItem('user');
      const agent = agents.find((a: any) => a.email === user);

      const permission = agent.permission;

      setAvailable(agent.available);
      setAgents(agents);
      setSelectedAgent(agentSelected);
      callBackParentFunction(agentSelected?.id, permission);
      setPermission(agentSelected?.permission);

      sessionStorage.setItem('available', agent.available);
    }

    async function fetch() {
      await setAllAgents();
    }

    fetch();
  }, []);

  useEffect(() => {
    //console.log(props);
    getNextAgents();
  }, [callBackParentFunction]);

  useEffect(() => {
    setIs_loading(true);
    getNextAgents();
  }, [selectedGroup]);

  async function handleSelectedAgent(agent_id: number) {
    const agentFinded = agents.find((agent) => agent.id === agent_id);

    setSelectedAgent(agentFinded);

    props.callbackFunction(agent_id, agentFinded?.permission);
  }

  const handleShowAgentProfile = () => {
    setShowAgentProfile(true);
  };

  const getNextAgents = async () => {
    const agent = JSON.parse(sessionStorage.getItem('selectedAgent'));

    if (sessionStorage.getItem('permission') === 'admin-super') {
      const res = await api.get(
        `/agents/team/order?group_dashboard=${selectedGroup}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      setLine(res.data.response_data);
    } else {
      const res = await api.get(
        `/agents/team/order?group_dashboard=${agent.group_dashboard}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      setLine(res.data.response_data);
    }
    const resTeam = await api.get(`/agents/teams`, {
      headers: {
        Authorization: authToken.token,
      },
    });
    setTeamList(resTeam.data.response_data.groups);

    setIs_loading(false);
  };

  const handleCloseProfile = (available: boolean) => {
    setAvailable(available);
    setShowAgentProfile(!showAgentProfile);
  };

  const handleSelectGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const group = e.target.value;

    setSelectedGroup(group);
  };

  const ruleImobs = agentSelected.group_dashboard.includes('eme_plantao_');

  /* const handleRefreshLeads = () => {
    props.refreshLeads();
  } */
  return (
    <HeaderContainer {...props}>
      <LogoContainer>
        <ReactSVG src={hmLogo} />
        <h1>
          Dashboard <span>Leads</span>
        </h1>

        {props.total_calls > 0 && (
          <TotalClientsContainer>
            <div className="totalLeads">
              <TotalClientsLabel>Total de Leads</TotalClientsLabel>

              <TotalClientsValue>{props.total_calls}</TotalClientsValue>
            </div>
            {(sessionStorage.getItem('permission') === 'admin' ||
              sessionStorage.getItem('permission') === 'admin-super') && (
              <>
                <CallsForRedistribute>
                  Atendimentos a serem distribuídos:{' '}
                  <strong>{props.callsForRedistribute}</strong>
                </CallsForRedistribute>
                <LastUpdate>
                  Última atualização: <strong>{props.dateAtual}</strong>
                </LastUpdate>
              </>
            )}
          </TotalClientsContainer>
        )}

        <NextAgentsContainer>
          {sessionStorage.getItem('permission') === 'admin-super' && (
            <div className="selectGroup">
              <select
                value={selectedGroup}
                onChange={(e) => handleSelectGroup(e)}
              >
                {teamList.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <TotalClientsLabel>Fila</TotalClientsLabel>
          {is_loading ? (
            <LoadingContainer>
              <Spinner animation="border" role="status" />
            </LoadingContainer>
          ) : (
            <TotalClientsValue>
              <ol id="menu">
                {line?.slice(0, 3).map((number) => (
                  <li key={number.id}>{number.full_name}</li>
                ))}
              </ol>
            </TotalClientsValue>
          )}
        </NextAgentsContainer>
      </LogoContainer>
      <SearchAndAgentContainer>
        {/*
            <SearchButton>
              <input placeholder="Pesquisar"/>
              <ReactSVG src={searchIcon} className="search-icon"/>
            </SearchButton>
            */}

        <ButtonsHeader>
          <ButtonShowContacts
            hidden={
              (permission && permission === 'corretor') || ruleImobs
                ? true
                : false
            }
            disabled={permission && permission === 'marketing' ? true : false}
            onClick={props.handleShowExportModal}
          >
            <MdLibraryBooks />
            <label>EXPORTAR</label>
          </ButtonShowContacts>
        </ButtonsHeader>

        <ButtonsHeader>
          <ButtonShowContacts
            hidden={
              (permission && permission === 'corretor') || ruleImobs
                ? true
                : false
            }
            disabled={permission && permission === 'marketing' ? true : false}
            onClick={props.handleShowReportModal}
          >
            <MdLibraryBooks />
            <label>RELATÓRIOS</label>
          </ButtonShowContacts>
        </ButtonsHeader>

        <Overlay
          target={target.current}
          show={showRefreshDialog}
          placement="bottom"
        >
          <Tooltip id="change-status-overlay">
            Os Leads só serão exibidos conforme a rotina de redistribuição
          </Tooltip>
        </Overlay>

        {selectedAgent && (
          <AgentContainer>
            <AgentAvatar onClick={() => handleShowAgentProfile()}>
              <span>{getInitalByFullname(selectedAgent.full_name)}</span>

              <AvailabilityIndicator available={available} />
            </AgentAvatar>
            <AgentName onClick={() => handleShowAgentProfile()}>
              {selectedAgent.full_name}
            </AgentName>

            <ProfileButton onClick={() => handleShowAgentProfile()}>
              <DotIndicador />

              <DotIndicador />

              <DotIndicador />
            </ProfileButton>

            {showAgentProfile && (
              <Profile
                selectAgent={selectedAgent}
                agent_id={selectedAgent.id}
                name={selectedAgent.full_name}
                permission={permission}
                available={available}
                handleCloseProfile={handleCloseProfile}
              />
            )}
          </AgentContainer>
        )}
      </SearchAndAgentContainer>
    </HeaderContainer>
  );
};

export default Header;
