import React from 'react';
import { Route as RouteWrapper, Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import Home from '../pages/Home';
import Register from '../pages/Register';
import RegisterLead from '../pages/RegisterLead';
import ForgotPassword from 'pages/ForgotPassword';
import TeamStatus from '../pages/TeamStatus';
import TeamRanking from '../pages/TeamRanking';
import RegisterContact from '../pages/RegisterContact';

import Lead from '../pages/Leads/';

import FacebookForm from '../pages/FacebookForm';
import { isAuthenticated } from 'services/auth/Authenticated';

//console.log('AQUI', isAuthenticated());

function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <RouteWrapper
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/register" exact component={Register} />
    <Route path="/register/lead" exact component={RegisterLead} />
    <Route path="/register/contact" exact component={RegisterContact} />
    <Route path="/team/status" exact component={TeamStatus} />
    <Route path="/team/ranking" exact component={TeamRanking} />
    <Route path="/forgotpassword" exact component={ForgotPassword} />
    <Route path="/team/ranking" exact component={TeamRanking} />
    <PrivateRoute path="/home" exact component={Home} />
    <PrivateRoute path="/lead/:id/:agentid" exact component={Lead} />
    <PrivateRoute path="/facebook/forms/home" exact component={FacebookForm} />
  </Switch>
);

export default Routes;
