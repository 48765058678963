const tokenSession = sessionStorage.getItem('token');

function parseJwt(token) {
  if (!token) {
    return;
  }
  token = token.substring(7);
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function ts2human(time) {
  let ts = time;
  let dt = new Date(ts * 1000);
  return { ts: ts, formattedDate: dt.toLocaleString() };
}

export function human2ts(data) {
  let di = data;
  let ts = Date.parse(di);
  return { tsSeconds: ts / 1000, tsMilliseconds: ts };
}

export const isAuthenticated = () => {
  //console.log('TOKEN', tokenSession);
  if (tokenSession === null) {
    return false;
  } else {
    const userLogged = parseJwt(tokenSession).agentId;
    const expira = parseJwt(tokenSession).exp;
    const data = new Date();
    const dataTS = human2ts(data).tsSeconds;
    if (userLogged !== null && expira > dataTS) {
      return true;
    } else {
      return false;
    }
  }
};
