import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Field } from 'formik';

import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import FormContext from 'context/FormContext';

import AlreadyRegistered from '../AlreadyRegistered';

import * as S from './styles';

interface StepTwoProps {
  alreadyRegistered: boolean;
  client: {
    full_name: string;
    email: string;
    agent_id: number;
    agent_name: string;
  };
}

const StepTwo: React.FC<StepTwoProps> = (props) => {
  const { fields, setFields } = useContext(FormContext);
  const [agents, setAgents] = useState([]);
  const [agent_id, setAgent_id] = useState(0);
  const [enterprises, setEnterprises] = useState([]);

  const { client } = props;

  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');
  const [dashboardAgent, setDashboardAgent] = useState('');

  function changeString(e, property) {
    if (property === 'agent_id') {
      setAgent_id(e.target.value);
    }
    if (property === 'group_dashboard') {
      setFields({ ...fields, [property]: e });
    }
    setFields({ ...fields, [property]: e.target.value });
  }

  async function changeDashboardEnterprise(e) {
    changeString(e, 'agent_id');
    //console.log('//console.log', e.target.value);
    let agentsSelect;
    if (selectedAgent.group_dashboard === 'hmgeral') {
      //console.log('Entrou aqui');
      agentsSelect = await api.get('/agents/search/' + e.target.value, {
        headers: {
          Authorization: authToken.token,
        },
      });
      //console.log('res', agentsSelect.data.agents[0].group_dashboard);
      const dashboard = setDashboardAgent(
        agentsSelect.data.agents[0].group_dashboard,
      );
      //console.log('dashboardAgent', dashboardAgent);
    }
  }

  const getAllAgents = useCallback(async () => {
    let agentsResponse;
    if (permission === 'admin' || permission === 'admin-super') {
      agentsResponse = await api.get('/agents', {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      agentsResponse = await api.get('/agents/search/' + selectedAgent.id, {
        headers: {
          Authorization: authToken.token,
        },
      });
    }

    const { agents } = agentsResponse.data;

    return agents;
  }, []);

  const getEnterprises = useCallback(async () => {
    const enteprisesResponse = await api.get(
      '/enterprises/list/searchgroup?group_dashboard=' +
        selectedAgent.group_dashboard,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, []);

  const getEnterprisesGeral = useCallback(async (dashboard: string) => {
    const enteprisesResponse = await api.get(
      '/enterprises/list/searchgroup?group_dashboard=' + dashboard,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();

      const filterValidAgents = agents.filter(
        (agent) => agent.permission === 'corretor' && agent.activated,
      );

      const filterAgentsbyGroup = filterValidAgents.filter((agent) => {
        if (client && client.agent_id === agent.id) {
          setAgent_id(client.agent_id);
        } else {
          setAgent_id(fields.agent_id);
        }
        if (selectedAgent.group_dashboard === 'hmgeral') {
          return agent;
        } else {
          return selectedAgent.group_dashboard === agent.group_dashboard;
        }
      });

      setAgents(filterAgentsbyGroup);
    }

    async function setAllEnterprises() {
      const enteprises = await getEnterprises();

      const filterValidEnterprises = enteprises.filter(
        (enterprise) => enterprise.slug !== '',
      );

      setEnterprises(filterValidEnterprises);
    }

    async function fetch() {
      await setAllAgents();
      await setAllEnterprises();
    }

    fetch();
  }, [getAllAgents, getEnterprises]);

  useEffect(() => {
    async function setAllEnterprises() {
      const enteprises = await getEnterprisesGeral(dashboardAgent);

      setEnterprises(enteprises);
    }

    async function fetch() {
      await setAllEnterprises();
    }

    fetch();
  }, [dashboardAgent]);

  const callTypes = [
    {
      callType: 'Agendar visita',
      value: 'visit',
    },
    {
      callType: 'Financiamento',
      value: 'financing',
    },
  ];
  return (
    <S.Container>
      {props.alreadyRegistered ? (
        <AlreadyRegistered agent_name={client.agent_name} />
      ) : (
        <>
          <Field
            className="fieldStyle"
            required
            placeholder="Nome"
            value={fields.full_name}
            onChange={(e) => changeString(e, 'full_name')}
          />

          <Field
            className="fieldStyle"
            required
            placeholder="E-mail"
            value={fields.email}
            onChange={(e) => changeString(e, 'email')}
          />

          <select
            name="agent"
            value={fields.agent_id}
            required
            onChange={(e) => changeDashboardEnterprise(e)}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Corretor
            </option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.full_name} - {agent.group_dashboard}
              </option>
            ))}
          </select>

          <select
            name="enterprise"
            value={fields.enterprise_slug}
            required
            onChange={(e) => changeString(e, 'enterprise_slug')}
            className="fieldStyle"
            disabled={
              permission === 'admin-super' && !fields.agent_id ? true : false
            }
          >
            <option
              value=""
              hidden
              disabled={
                permission === 'admin-super' && !fields.agent_id ? true : false
              }
            >
              Empreendimento
            </option>
            {enterprises.map((enterprise) => (
              <option key={enterprise.slug} value={enterprise.slug}>
                {enterprise.title}
              </option>
            ))}
          </select>

          <select
            name="call_type"
            required
            value={fields.call_type}
            onChange={(e) => changeString(e, 'call_type')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Tipo do atendimento
            </option>
            {callTypes.map((callType) => (
              <option key={callType.value} value={callType.value}>
                {callType.callType}
              </option>
            ))}
          </select>
        </>
      )}
    </S.Container>
  );
};

export default StepTwo;
